// src/components/PriceDisplay.js

import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, TextField, Button, Link } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import axios from 'axios';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import TopCryptoLoans from './TopCryptoLoans';
import PopularLinks from './PopularLinks';
import PaymentPopup from './PaymentPopup';

// Retro button with ! and ? signs styled as a white box with shaded blue interior
const RetroSquareButton = styled('div')(({ theme }) => ({
  width: '96px',  // 1 inch width
  height: '96px', // 1 inch height
  backgroundColor: '#1a237e', // Shaded blue interior
  border: '4px solid #FFFFFF', // White box border
  color: '#FFFFFF', // White text color
  fontSize: '3rem', // Size of the ! or ? symbol
  fontFamily: "'Press Start 2P', cursive", // Retro font
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `, // Black pixelated shadow for retro effect
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer', // Make it clickable
  '&:hover': {
    backgroundColor: '#283593', // Darker blue on hover
  },
  position: 'absolute', // For positioning in the corners
}));

// Styled $ button for payment popup, lower right
const PaymentButton = styled(RetroSquareButton)(({ theme }) => ({
  position: 'fixed',
  bottom: '20px',
  right: '20px', // Lower right corner
}));

// Buttons with specific content and links
const LeftButton = () => (
  <a href="https://wheel.satoshiadvance.com" target="_blank" rel="noopener noreferrer">
    <RetroSquareButton
      sx={{ 
        top: '20px', 
        left: '20px',
        position: 'fixed' // This will keep the button fixed on the screen
      }} 
    >
      !
    </RetroSquareButton>
  </a>
);

const RightButton = () => (
  <a href="https://tanks.satoshiadvance.com" target="_blank" rel="noopener noreferrer">
    <RetroSquareButton
      sx={{ 
        top: '20px', 
        right: '20px',
        position: 'fixed' // This will keep the button fixed on the screen
      }} 
    >
      ?
    </RetroSquareButton>
  </a>
);

// Add this code below your existing LeftButton and RightButton components

const BottomLeftButton = () => (
  <a href="https://flip.satoshiadvance.com" target="_blank" rel="noopener noreferrer">
    <RetroSquareButton
      sx={{ 
        bottom: '20px', 
        left: '20px',
        position: 'fixed', // Keep the button fixed on the screen
      }} 
    >
      ₿
    </RetroSquareButton>
  </a>
);


const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF', // White lettering
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `, // Thin black outline
  fontWeight: 'bold',
  fontSize: '4rem', // Adjust size as needed
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive", // Retro font
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const RetroIconButton = styled(motion(IconButton))(({ theme }) => ({
  color: '#FFFFFF',
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
  fontSize: '4rem',
  marginRight: '20px',
  '& .MuiSvgIcon-root': {
    fontSize: '4rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },
  },
}));

const DividerBar = styled(motion('div'))({
  width: '80%', // Final width of the bar (almost full screen)
  height: '4px', // Thickness of the bar
  backgroundColor: '#FFFFFF',
  margin: '20px auto 0',
  marginBottom: '30px',
  transformOrigin: 'center', // Ensure it grows from the center point
  // Light blue and white glowing effect
  boxShadow: `
    0 0 10px #FFFFFF,
    0 0 20px #00BFFF, /* Light blue glow */
    0 0 30px #00BFFF,
    0 0 40px #00BFFF,
    0 0 70px #FFFFFF, /* White glow */
    0 0 80px #FFFFFF,
    0 0 100px #00BFFF,
    0 0 150px #00BFFF
  `,
});

const MotionDivider = () => {
  return (
    <DividerBar
      initial={{ scaleX: 0 }} // Start as a point in the middle
      animate={{
        scaleX: 1, // Grow to full width
        rotateX: 15, // Tilt back at a 15-degree angle
      }}
      transition={{
        duration: 2, // Grow over 2 seconds
        ease: "easeInOut", // Smooth start and stop
      }}
    />
  );
};

const EmailInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontFamily: "'Press Start 2P', cursive",
    fontSize: '1rem',
    color: '#FFFFFF',
    backgroundColor: '#283593',
    borderRadius: '4px',
    width: '250px',
    height: '40px',
    boxSizing: 'border-box',
    padding: '10px 14px',
    border: '2px solid #FFFFFF', // Explicitly set the border to white
    transition: 'background-color 0.3s ease-in-out', // Smooth transition for hover effect
    '&:hover': {
      backgroundColor: '#3949ab', // Darker shade of blue when hovered
      borderColor: '#FFFFFF', // Ensure the white border stays on hover
    },
    '&.Mui-focused': {
      borderColor: '#FFFFFF', // Ensure white border stays on focus
    },
    '& fieldset': {
      border: 'none', // Disable the default fieldset border
    },
  },
  '& .MuiInputBase-input': {
    color: '#FFFFFF', // White text color inside the input
    padding: '10px 0',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Ensure no conflicting borders are applied
  },
}));

const SignUpButton = styled(Button)(({ theme }) => ({
  fontFamily: "'Press Start 2P', cursive",
  fontSize: '1rem',
  color: '#FFFFFF',
  backgroundColor: '#283593',
  border: '2px solid #FFFFFF',
  borderRadius: '4px',
  padding: '10px 20px',
  marginLeft: '10px',
  textTransform: 'uppercase',
  height: '40px', // Set same height as EmailInput
  '&:hover': {
    backgroundColor: '#1a237e',
    borderColor: '#FFFFFF',
  },
}));

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '80%',
  borderBottom: '1px solid #fff',
  height: '30px',
  position: 'fixed',
  top: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  backgroundColor: '#283593',
  paddingTop: '5px'
});

const HeaderTitle = styled(Typography)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '22px',
  color: '#fff',
  marginBottom: '2px',
});

const HeaderLink = styled(Link)({
  fontFamily: "'Roboto', sans-serif",
  fontSize: '19px',
  color: '#fff',
  textDecoration: 'none',
  marginBottom: '2px',
  '&:hover': {
    color: '#ccc',
  },
});

const PriceDisplay = () => {
  const [price, setPrice] = useState(null);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // For payment popup

  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    loanAmount: '',
    useOfFunds: '',
    country: ''
  });

  const toggleMusic = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play().then(() => {
          setIsPlaying(true);
        }).catch((error) => {
          console.log('Error playing audio:', error);
        });
      }
    }
  };

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        const response = await axios.get('/api/btc-price');
        const formattedPrice = parseFloat(response.data.price).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        });
        setPrice(formattedPrice);
      } catch (error) {
        console.error('Error fetching BTC price:', error);
        setPrice('N/A');
      }
    };

    fetchBTCPrice();

    // Optional: Update the price every minute
    const interval = setInterval(fetchBTCPrice, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Attempt to autoplay the music when the component mounts
    if (audioRef.current) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        console.log('Autoplay failed:', error);
        // Autoplay was prevented; user interaction is required
      });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStep === 1) {
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        setEmailValid(false);
        return;
      }
      setEmailValid(true);
      setFormStep(2);
    } else {
      try {
        const response = await axios.post('/api/signup', formData);
        alert('Thank you for signing up!');
        setFormStep(1);
        setFormData({
          email: '',
          loanAmount: '',
          useOfFunds: '',
          country: ''
        });
      } catch (error) {
        console.error('Error signing up:', error);
        alert('Failed to sign up. Please try again.');
      }
    }
  };

  const openPaymentPopup = () => {
    setIsPopupOpen(true);
  };

  const closePaymentPopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        backgroundColor: '#283593',
        padding: '45px 20px 50px 20px', // Increased top padding to account for fixed header
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <HeaderContainer>
        <HeaderLink href="/sats">Sats</HeaderLink>
        <HeaderTitle>Satoshi Advance</HeaderTitle>
        <HeaderLink href="/login">Login</HeaderLink>
      </HeaderContainer>
      
      <LeftButton />
      <RightButton />
      <BottomLeftButton />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <RetroIconButton
          onClick={toggleMusic}
          initial={{ opacity: 0, x: -50, rotate: 0, y: 0 }}
          animate={{ opacity: 1, x: 0, rotate: [0, 360, 0], y: [0, -10, 0] }}
          transition={{
            opacity: { duration: 1 },
            x: { duration: 1 },
            rotate: {
              duration: 1.5,
              ease: "linear",
              times: [0, 0.9, 1],
              repeat: Infinity,
              repeatDelay: 15,
            },
            y: {
              duration: 0.5,
              ease: "easeInOut",
              times: [0, 0.9, 1],
              repeat: Infinity,
              repeatDelay: 15,
            },
          }}
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </RetroIconButton>
        <RetroText
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 1 }}
        >
          {price || '$0.00'}
        </RetroText>
      </Box>
      {/* Rest of your existing components */}
      <MotionDivider />
      <Box
  component="form"
  onSubmit={handleSubmit}
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    width: '100%'
  }}
>
  {formStep === 1 ? (
    <EmailInput
      placeholder="E-mail"
      value={formData.email}
      onChange={(e) => setFormData({...formData, email: e.target.value})}
      required
      error={!emailValid && formData.email !== ''}
      helperText={!emailValid && formData.email !== '' ? 'Invalid email address' : ''}
    />
  ) : (
    <Box sx={{ 
      display: 'flex', 
      gap: '2px',
      width: '40%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: { xs: 'column', md: 'row' }
    }}>
      <EmailInput
        placeholder="Loan Amount"
        value={formData.loanAmount}
        onChange={(e) => setFormData({...formData, loanAmount: e.target.value})}
        required
        sx={{ flex: 1 }}
      />
      <EmailInput
        placeholder="Use of Funds"
        value={formData.useOfFunds}
        onChange={(e) => setFormData({...formData, useOfFunds: e.target.value})}
        required
        sx={{ flex: 1 }}
      />
      <EmailInput
        placeholder="Country"
        value={formData.country}
        onChange={(e) => setFormData({...formData, country: e.target.value})}
        required
        sx={{ flex: 1 }}
      />
    </Box>
  )}
  <SignUpButton 
    type="submit"
    sx={{ marginTop: '10px' }}
  >
    {formStep === 1 ? 'Next' : 'Submit'}
  </SignUpButton>
</Box>
      <audio ref={audioRef} src="/UpbeatRetroIntro.wav" loop />
      <TopCryptoLoans />
      <PopularLinks />
      <PaymentButton onClick={openPaymentPopup}>
        $
      </PaymentButton>
      {isPopupOpen && <PaymentPopup onClose={closePaymentPopup} />}
    </Box>
  );
};

export default PriceDisplay;
