import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Checkbox, FormControlLabel, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import { QRCodeCanvas } from 'qrcode.react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// Styled Components for the Popup
const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  padding: '20px',
  backgroundColor: '#1a237e', // Dark blue background
  borderRadius: '10px',
  textAlign: 'center',
  zIndex: 1000, // Ensure it overlays the main screen
  boxShadow: '0px 0px 20px 0px #00BFFF', // Glowing effect
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 999, // Just below the popup container
}));

const RetroTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF', // White retro text
  fontSize: '1.8rem',
  textShadow: `0 0 10px #FFFFFF, 0 0 20px #00BFFF, 0 0 30px #00BFFF`, // Glowing effect
  fontFamily: "'Press Start 2P', cursive",
  marginBottom: '20px',
}));

const SummaryText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '1rem',
  marginBottom: '15px',
}));

const CopyField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#283593',
  borderRadius: '5px',
  marginBottom: '15px',
  input: {
    color: '#FFFFFF',
    fontFamily: "'Press Start 2P', cursive",
  },
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '0.9rem',
  marginTop: '-10px',
  marginBottom: '10px',
  textAlign: 'left',
}));

const PaymentPopup = ({ onClose }) => {
  const [btcAmount, setBtcAmount] = useState(0.00015);
  const [confirmChecked, setConfirmChecked] = useState(false);
  const btcAddress = '3KybzUMnSGkJMNmoshXD7wwaeLuENQNpNB'; // Your BTC address

  const handleBtcAmountChange = (event) => {
    setBtcAmount(event.target.value);
  };

  const handleIncrement = () => {
    setBtcAmount((prevAmount) => {
      const newAmount = (parseFloat(prevAmount) + 0.00001).toFixed(8); // Increment BTC amount
      return newAmount;
    });
  };

  const handleDecrement = () => {
    setBtcAmount((prevAmount) => {
      const newAmount = Math.max(parseFloat(prevAmount) - 0.00001, 0).toFixed(8); // Decrement BTC amount but not below zero
      return newAmount;
    });
  };

  const handleConfirmChange = (event) => {
    setConfirmChecked(event.target.checked);
  };

  const handleSendReceipt = () => {
    if (confirmChecked) {
      alert('Receipt sent! Thank you for your payment.');
    } else {
      alert('Please confirm the payment before sending the receipt.');
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(btcAddress);
    alert('BTC Address Copied!');
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <PopupContainer>
        {/* Title */}
        <RetroTitle>Subscription Upgrade</RetroTitle>

        {/* Subscription Info */}
        <SummaryText>
          Confirm 1 Time Annual Subscription of $10, or 15 Satoshis (0.00015 BTC)
        </SummaryText>

        {/* QR Code with address only */}
        <QRCodeCanvas value={btcAddress} size={180} style={{ margin: '20px auto' }} />

        {/* BTC Address Field */}
        <CopyField
          value={btcAddress}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ContentCopyIcon
                  onClick={handleCopyToClipboard}
                  sx={{ cursor: 'pointer', color: '#FFFFFF' }}
                />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <LabelText onClick={handleCopyToClipboard}>Click to copy</LabelText>

        {/* BTC Amount Field with Increment and Decrement */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <IconButton
            onClick={handleDecrement}
            sx={{ color: '#FFFFFF', backgroundColor: '#283593', borderRadius: '5px' }}
          >
            <RemoveIcon />
          </IconButton>
          <CopyField
            value={btcAmount}
            onChange={handleBtcAmountChange}
            fullWidth
            sx={{ textAlign: 'center' }}
          />
          <IconButton
            onClick={handleIncrement}
            sx={{ color: '#FFFFFF', backgroundColor: '#283593', borderRadius: '5px' }}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <LabelText>BTC Amount</LabelText>

        {/* Summary */}
        <SummaryText>
          You confirm this 1 time payment, with no auto pay, for 1 year annual subscription to SatoshiAdvance.com in exchange for {btcAmount} BTC or 15,000 Satoshis, approximately $10 USD as of 10.24.2024.
        </SummaryText>

        {/* Checkbox */}
        <FormControlLabel
          control={<Checkbox checked={confirmChecked} onChange={handleConfirmChange} sx={{ color: '#00BFFF' }} />}
          label={<Typography sx={{ color: '#FFFFFF', fontSize: '0.9rem' }}>Confirm Payment</Typography>}
        />

        {/* Send Receipt Button */}
        <Button
          onClick={handleSendReceipt}
          sx={{
            backgroundColor: '#00BFFF',
            color: '#FFFFFF',
            fontFamily: "'Press Start 2P', cursive",
            textTransform: 'none',
            '&:hover': { backgroundColor: '#00BFFF' },
          }}
          fullWidth
        >
          Email Receipt
        </Button>
      </PopupContainer>
    </>
  );
};

export default PaymentPopup;
