// src/App.js

import React from 'react';
import PriceDisplay from './components/PriceDisplay';

function App() {
  return (
    <div>
      <PriceDisplay />
      {/* You can add more components here */}
    </div>
  );
}

export default App;
