// src/components/PopularLinks.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

// Define the Retro styled typography
const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF', // White lettering
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `, // Thin black outline
  fontWeight: 'bold',
  fontSize: '2.5rem', // Slightly larger title
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive", // Retro font
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

// Container for the entire Popular Links section
const PopularLinksContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#1a237e', // Same background as LoanBox
  padding: '20px',
  borderRadius: '10px',
  width: '45%', // Set the width to 60% for indentation from left
  margin: '0 auto', // Center the container
  marginTop: '20px', // Space above container
  marginLeft: '28%', // Align to the left side under the "P"
  textAlign: 'left', // Left-align text inside the container
}));

// Style for the bullet points container to ensure alignment
const BulletList = styled('ul')({
  paddingLeft: '0px', // Reset padding
  listStyle: 'none',  // Remove default bullet points
  margin: 0,
});

// Custom glowing bullet point styled as a white circle with blue glow
const GlowingBullet = styled(motion('span'))({
  display: 'inline-block',
  width: '10px', // Adjust bullet point size
  height: '10px',
  backgroundColor: '#FFFFFF', // White bullet point
  borderRadius: '50%', // Circular shape
  marginRight: '20px', // Space between bullet and text
  marginLeft: '50px', // Space between bullet and text
  boxShadow: `0 0 5px #00BFFF, 0 0 10px #00BFFF, 0 0 15px #00BFFF`, // Blue glowing effect
  verticalAlign: 'middle', // Align the bullet with text
});

// Style for each link text
const LinkText = styled(motion('a'))({
  color: '#00BFFF', // Blue color for links
  textDecoration: 'none', // Remove underline
  fontSize: '1.3rem', // Slightly larger font size for links
  display: 'inline-block',
  marginBottom: '15px', // Add spacing between each item
  marginLeft: '10px', // Add spacing between each item
});

const PopularLinks = () => {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get('/api/popular-links'); // Fetch popular links from backend
        setLinks(response.data);
      } catch (error) {
        console.error('Error fetching popular links:', error);
      }
    };

    fetchLinks();
  }, []);

  if (links.length === 0) {
    return <Typography sx={{ color: '#FFFFFF' }}>Loading...</Typography>;
  }

  return (
    <>
      {/* Moved Popular Links Title Outside */}
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <RetroText>Popular Links</RetroText>
      </Box>
      <PopularLinksContainer>
        <BulletList>
          {links.slice(0, 10).map((link, index) => (
            <li key={link._id} style={{ display: 'flex', alignItems: 'center' }}>
              <GlowingBullet
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }} // Staggered appearance for each bullet
              />
              <LinkText
                href={link.URL}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{
                  rotateY: 360, // Flip animation on hover
                }}
                transition={{
                  duration: 0.6,
                  ease: 'easeInOut',
                }}
              >
                {link.URL}
              </LinkText>
            </li>
          ))}
        </BulletList>
      </PopularLinksContainer>
    </>
  );
};

export default PopularLinks;
