// src/components/TopCryptoLoans.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

// Define the Retro styled typography
const RetroText = styled(motion(Typography))(({ theme }) => ({
  color: '#FFFFFF', // White lettering
  textShadow: `
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `, // Thin black outline
  fontWeight: 'bold',
  fontSize: '2rem', // Adjust size as needed
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontFamily: "'Press Start 2P', cursive", // Retro font
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const LoanBox = styled(motion(Box))(({ theme }) => ({
  backgroundColor: '#1a237e',
  padding: '20px',
  borderRadius: '10px',
  marginBottom: '20px',
  color: '#FFFFFF',
  width: '80%', // Set the width to 80%
  marginLeft: 'auto', // Center the box
  marginRight: 'auto', // Center the box
}));

const RetroLink = styled('a')({
  color: '#00BFFF',
  textDecoration: 'none',
  fontWeight: 'bold',
  display: 'block', // Ensure the link is a block-level element
  margin: '10px 0', // Add some space above and below the link
  '&:hover': {
    textDecoration: 'underline',
  },
});

const TopCryptoLoans = () => {
  const [loans, setLoans] = useState([]); // Set to an array to handle multiple entries

  useEffect(() => {
    const fetchLoans = async () => {
      try {
        const response = await axios.get('/api/top-loan-companies'); // Adjust the endpoint if needed
        setLoans(response.data); // Store multiple loan companies
      } catch (error) {
        console.error('Error fetching loans:', error);
      }
    };

    fetchLoans();
  }, []);

  if (loans.length === 0) {
    return <Typography sx={{ color: '#FFFFFF' }}>Loading...</Typography>;
  }

  return (
    <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
      <RetroText>2024's Top 10 Crypto Loans</RetroText>
      {loans.map((loan, index) => (
        <LoanBox
          key={loan._id}
          initial={{
            opacity: 0,
            x: index % 2 === 0 ? '-100vw' : '100vw', // Alternate slide direction
            rotateY: -90, // Start flipped
          }}
          animate={{
            opacity: 1,
            x: 0, // Center position
            rotateY: 0, // End with no rotation
          }}
          transition={{
            duration: 1, // Animation duration
            delay: index * 0.3, // Delay for sequential entry
            type: 'spring', // Spring effect for smooth motion
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            #{index + 1} {loan.Name} - Founded: {loan.Founded}
          </Typography>
          {loan.URL && (
            <RetroLink href={loan.URL} target="_blank" rel="noopener noreferrer">
              {loan.URL}
            </RetroLink>
          )}
          <Typography variant="body1" sx={{ marginTop: '10px' }}>
            {loan.Description}
          </Typography>
        </LoanBox>
      ))}
    </Box>
  );
};

export default TopCryptoLoans;
